@import 'media-queries';

///
/// @file base/_typography.scss
///
/// \brief Reusable, generic typographic styles used throughout the site.
///
/// Guidelines:
///
/// No layout-related properties (float, margin, padding, position, display,
/// etc.), no template-specific stuff, try to follow the existing naming
/// conventions and method of creating both mixins and extendable placeholders.

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark backgrounds
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// Base styles: each typeface has optimal type treatment that remains consitent
// across the site.
//$font--heading: "Copperplate Gothic Std 32 AB", sans-serif;
/*
$font--heading: 'OriginsDCopperHead-Regular', sans-serif !default;
$font--heading--secondary: 'OriginsECaslonHead-Thin', sans-serif !default;
$font--heading--secondary--italic: 'OriginsECaslonHead-ThinItalic', sans-serif !default;
$font--heading--tertiary: 'Helvetica-Medium', sans-serif !default;
$font--heading--tertiary--regular: 'Helvetica-Roman', sans-serif !default;
$font--subheading: 'ITC Franklin Gothic', sans-serif !default;
//$font--text: "Adobe Caslon Pro", serif;
$font--text: 'OriginsECaslon-Regular', serif !default;
$font--text--italic: 'OriginsECaslon-Italic', serif !default;
$font--cta: 'Typewriter Elite MT Std', serif !default;
$font--typewriter: 'Typewriter Elite MT Std', serif !default;
$font--subheading--secondary: 'Adobe Caslon Pro', serif !default;
$font--banner: 'Gotham', sans-serif;
*/

// 2021 Font replacements - matches cr18
$font--heading: 'Futura-Book', sans-serif !default;
$font--heading--bold: 'Futura-Book Bold', sans-serif !default;
$font--heading--secondary: 'Futura-Book', sans-serif !default;
$font--heading--secondary--italic: 'Futura-Book', sans-serif !default;
$font--heading--tertiary: 'Futura-Medium', sans-serif !default;
$font--heading--tertiary--regular: 'Futura-Medium', sans-serif !default;
$font--subheading: 'Futura-Book', sans-serif !default;
$font--subheading--secondary: 'Futura-Medium', sans-serif !default;
$font--text: 'Futura-Book', sans-serif !default;
$font--text--italic: 'Futura-Book', sans-serif !default;
$font--cta: 'Futura-Book', sans-serif !default;
$font--typewriter: 'Futura-Book', sans-serif !default;
$font--banner: 'Futura-Book', sans-serif !default;

// Creative Refresh 2018 adding variable for the new fonts
$font--futura-book: 'Futura-Book', sans-serif !default;
$font--futura-demi: 'Futura-Demi', sans-serif !default;
$font--futura-medium: 'Futura-Medium', sans-serif !default;
$font--futura: 'Futura-Book', sans-serif !default;
$font--futura-plain: 'Futura', sans-serif !default;

// Creative Refresh 2018 - changing font variables here before they are called
// in the mixins below
$cr18: false !default;

$main-font: $font--heading;
$bold-font-family: $font--heading--bold;

@if $cr18 == true {
  $font--heading: 'Futura-Book', sans-serif;
  $font--heading--secondary: 'Futura-Book', sans-serif;
  $font--heading--secondary--italic: 'Futura-Book', sans-serif;
  $font--heading--tertiary: 'Futura-Medium', sans-serif;
  $font--heading--tertiary--regular: 'Futura-Medium', sans-serif;
  $font--subheading: 'Futura-Book', sans-serif;
  $font--subheading--secondary: 'Futura-Medium', sans-serif;
  $font--text: 'Futura-Book', sans-serif;
  $font--text--italic: 'Futura-Book', sans-serif;
  $font--cta: 'Futura-Book', sans-serif;
  $font--typewriter: 'Futura-Book', sans-serif;
  $font--banner: 'Futura-Book', sans-serif;
}

@mixin font--heading {
  font-family: $font--heading;
  text-transform: uppercase;
  // Global change requested by brand:
  // letter-spacing: 0.1em;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.143;
}

@mixin font--heading--secondary {
  font-family: $font--heading--secondary;
  //text-transform: uppercase;
  // Global change requested by brand:
  // letter-spacing: 0.1em;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
}

@mixin font--heading--secondary--italic {
  font-family: $font--heading--secondary--italic;
  //text-transform: uppercase;
  // Global change requested by brand:
  // letter-spacing: 0.1em;
  letter-spacing: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1.2;
}

@mixin font--heading--tertiary {
  font-family: $font--heading--tertiary;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  text-transform: uppercase;
}

@mixin font--heading--tertiary--regular {
  font-family: $font--heading--tertiary--regular;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  text-transform: uppercase;
}

@mixin font--subheading {
  // I am well aware antialiasing is a bad thing to do globally:
  // http://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
  // The brand, however, wants what it wants.
  @include font-smoothing(antialiased);
  font-family: $font--subheading;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: normal;
  line-height: 1;
}

@mixin font-small {
  font-size: 16px;
  @include breakpoint($largest-up) {
    font-size: 18px;
  }
}

@mixin font-medium {
  font-size: 26px;
  @include breakpoint($largest-up) {
    font-size: 32px;
  }
}

@mixin font-large {
  font-size: 32px;
  @include breakpoint($largest-up) {
    font-size: 40px;
  }
}

@mixin font-x-large {
  font-size: 40px;
  @include breakpoint($largest-up) {
    font-size: 50px;
  }
}

@mixin font-x-large-banner {
  font-size: 34px;
  @include breakpoint($largest-up) {
    font-size: 40px;
  }
}

@mixin font--text {
  font-family: $font--text;
  // Global change requested by brand:
  // letter-spacing: 0.025em;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
}

@mixin font--text--bold {
  font-family: $bold-font-family;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;
}

@mixin font--text--italic {
  font-family: $font--text--italic;
  // Global change requested by brand:
  // letter-spacing: 0.025em;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  font-style: normal;
}

@mixin font--text-alt {
  font-family: $font--text--italic;
  //font-family: $font--text--italic;
  // Global change requested by brand:
  // letter-spacing: 0.05em;
  letter-spacing: 0;
  //font-style: italic;
  font-weight: normal;
  line-height: 1.2;
}

@mixin font--cta {
  font-family: $font--cta;
  letter-spacing: 0.04em;
  font-weight: normal;
  line-height: 1;
}

///
/// These are some common font sizes, as defined by the styleguide.
///

///
/// Headlines
///

/// "Torling Copperplate" and "Copperplate Gothic Std" have very different font
/// sizes, Copperplate Gothic Std needs to be roughly 50% larger.
@mixin h1 {
  @include font--heading;
}

@mixin h2 {
  @include font--heading;
}

@mixin h3 {
  @include font--heading;
}

@mixin h4 {
  @include font--heading;
}

@mixin h5 {
  @include font--heading;
  // font-size: 14px;
  font-size: 21px;
}

@mixin h6 {
  @include font--heading;
  // font-size: 12px;
  font-size: 24px;
}

@mixin h7 {
  @include font--heading;
  // font-size: 10px;
  font-size: 14px;
}

@mixin h8 {
  @include font--subheading;
  font-size: 60px;
}

@mixin h9 {
  @include font--subheading;
  font-size: 34px;
}

@mixin h10 {
  @include font--subheading;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 1rem;
}

@mixin h11 {
  @include font--subheading;
  font-size: 24px;
}

@mixin h12 {
  @include font--subheading;
  font-size: 21px;
}

@mixin h13 {
  @include font--subheading;
  font-size: 18px;
}

@mixin h14 {
  @include font--subheading;
  font-size: 16px;
}

@mixin h15 {
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin: 0 auto 15px;
  padding: 0 33px;
  &::before {
    content: '';
    background: $color-black;
    width: 100%;
    height: 1px;
    display: inline-block;
    position: absolute;
    top: 43%;
    left: 0;
  }
  .inner,
  &__inner {
    background: $color-white;
    display: inline-block;
    position: relative;
    padding: 0 7px 0 10px;
  }
}

///
/// Text
///
@mixin t1 {
  @include font--text;
  font-size: 14px;
}

@mixin t2 {
  @include font--text;
  font-size: 16px;
}

@mixin t2--bold {
  @include font--text--bold;
  font-size: 16px;
}

@mixin t3 {
  @include font--text;
  font-size: 18px;
}

@mixin t4 {
  @include font--text;
  font-size: 24px;
}

@mixin t5 {
  @include font--text;
  font-size: 30px;
}

@mixin t6 {
  @include font--text-alt;
  font-size: 18px;
}

@mixin t7 {
  @include font--text-alt;
  font-size: 21px;
}

@mixin t8 {
  @include font--text-alt;
  font-size: 24px;
}

@mixin t9 {
  @include font--text-alt;
  font-size: 34px;
}

@mixin t10 {
  @include font--cta;
  font-size: 14px;
}

///
/// Links
///
@mixin a1 {
  @include font--cta;
  font-size: 14px;
  text-transform: uppercase;
}

@mixin typography--cr18 {
  h1 {
    font-size: 70px;
    letter-spacing: 1px;
    @include breakpoint($medium-up) {
      font-size: 60px;
    }
  }
  h2 {
    font-size: 60px;
    letter-spacing: 1px;
    @include breakpoint($medium-up) {
      font-size: 55px;
    }
  }
  h3 {
    font-size: 30px;
    letter-spacing: 1px;
    @include breakpoint($medium-up) {
      letter-spacing: 2px;
    }
  }
  h4 {
    letter-spacing: 1px;
    @include breakpoint($medium-up) {
      font-size: 30px;
      letter-spacing: 2px;
    }
  }
  p {
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 1.42;
    margin-bottom: 6px;
    @include breakpoint($medium-up) {
      font-size: 15px;
    }
  }
}

@mixin text-style--large-title {
  text-transform: uppercase;
  font-size: 32px;
  line-height: normal;
  font-family: $font--futura-demi;
  letter-spacing: normal;
  -webkit-text-stroke: 0.9px;
  @include breakpoint($medium-up) {
    font-weight: bold;
    font-size: 60px;
    -webkit-text-stroke: 0;
  }
}

@mixin text-style--large-title-tight {
  @include text-style--large-title;
  line-height: 0.85;
}

@mixin text-style--large-title-thin {
  @include text-style--large-title;
  font-family: $font--futura-book;
}

@mixin text-style--large-title-thin-tight {
  @include text-style--large-title;
  font-family: $font--futura-book;
  line-height: 0.85;
}

@mixin text-style--large-title--shadow {
  @include text-style--large-title;
  text-shadow: 1px 1px 5px rgba($color-black, 0.5);
}

@mixin text-style--tout-title {
  text-transform: uppercase;
  font-size: 28px;
  line-height: normal;
  font-family: $font--futura-book;
  @include breakpoint($medium-up) {
    font-size: 32px;
  }
}

@mixin text-style--section-title {
  text-transform: uppercase;
  font-size: 18px;
  line-height: normal;
  font-family: $font--futura-demi;
  @include breakpoint($medium-up) {
    font-size: 32px;
  }
}

@mixin text-style--storytelling-body {
  font-size: 18px;
  line-height: normal;
  font-family: $font--futura-book;
  -webkit-text-stroke: 0.1px;
  @include breakpoint($medium-up) {
    font-size: 24px;
    -webkit-text-stroke: 0;
  }
}

@mixin text-style--body {
  font-family: $font--futura-book;
  @include breakpoint($medium-up) {
    font-size: 18px;
  }
}

@mixin text-style--eyebrow {
  text-transform: uppercase;
  line-height: 1;
  font-size: 16px;
  font-family: $font--futura-demi;
  @include breakpoint($medium-up) {
    font-size: 18px;
  }
}

@mixin text-style--subcopy {
  line-height: 1;
  font-size: 12px;
  font-family: $font--futura-book;
  @include breakpoint($medium-up) {
    font-size: 14px;
  }
}

@mixin text-style--disclaimer {
  line-height: 1;
  font-size: 12px;
  color: $color-mid-gray;
  font-family: $font--futura-book;
}

h1,
h2,
h3 {
  letter-spacing: 0;
  line-height: normal;
  font-weight: initial;
}

h1 {
  @include text-style--large-title;
}

h2 {
  @include text-style--section-title;
  text-transform: none;
}

h3,
h4 {
  @include text-style--storytelling-body;
}

@mixin headline--tertiary {
  @include color-states;
  font-family: $font--futura-plain;
  font-size: 1.25rem;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 1rem;
}

@mixin headline--secondary {
  font-size: 4.5rem;
  font-family: $font--futura-plain;
  letter-spacing: -0.02em;
  @include color-states;
}

@mixin headline--senary {
  font-size: 1rem;
  font-family: $font--futura-plain;
  letter-spacing: 0;
  @include color-states;
  @media #{$medium-up-oab} {
    font-size: 1rem;
  }
}

@mixin body-text-override {
  font-family: $font--futura-plain;
  font-size: 1.125rem;
}

@mixin text--long-copy--plain {
  font: 18px/22px $font--futura-plain;
}

/// Bold text
@mixin text--bold {
  @include text--short--plain;
  font-weight: bold;
}

// minus brand styles
@mixin text--short--plain {
  font-size: 1rem;
  font-family: $font--futura-plain;
  text-transform: uppercase;
}
/// Form Text
@mixin form-text {
  @include text--short--plain;
  text-transform: none;
}
