///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules.
///

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input placeholders
///
@mixin input-styled-check($icon, $suffix: 'checked', $padding: 15px) {
  // hide the actual element
  position: absolute;
  left: -9999px;
  ~ label,
  ~ .label {
    @include icon($icon);
    &::before {
      margin-right: $padding;
    }
  }
  &:checked {
    ~ label,
    ~ .label {
      @include icon($icon + '--' + $suffix);
    }
  }
}

///
/// Retina Images
///
/// Usage:
/// @include image-2x('logo2x.png', 100px, 25px);
/// Note: Retina images should follow naming convention of 'image@2x.png'
///
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: image-url($image);
    background-size: $width $height;
  }
}

///
/// Circles
///
/// Usage:
/// @include circle(200px, #999);
///
@mixin circle($default-circle-width: 41px, $color: $color-light-gray) {
  width: $default-circle-width;
  height: $default-circle-width;
  background: $color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin bordered-triangle($size, $color, $direction, $border-color, $border-width: 1px, $cut: true) {
  $back-size: $size + ($border-width * 2);
  position: relative;
  min-width: $size;
  min-height: $size;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    @include triangle($size, $color, $direction);
  }
  &:before {
    @include triangle($back-size, $border-color, $direction);
    $mid-border-width: ($border-width / 2);
    $border-width-and-mid: $border-width + $mid-border-width;
    @if ($cut == true) {
      $mid-border-width: 0;
      $border-width-and-mid: $border-width * 2;
    }
    @if ($direction == up) or ($direction == down) {
      margin-left: -$border-width;
      @if ($direction == down) {
        margin-top: -$mid-border-width;
      } @else {
        margin-top: -$border-width-and-mid;
      }
    } @else if ($direction == right) or ($direction == left) {
      margin-top: -$border-width;
      @if ($direction == right) {
        margin-left: -$mid-border-width;
      } @else {
        margin-left: -$border-width-and-mid;
      }
    }
  }
}

///
/// Vertical centerer
/// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
/// Note: This doesn't work in old browsers (ie. IE), you have to use javascript
/// for them if it's important.
///
@mixin vertical-center {
  @include transform(translateY(-50%));
  position: relative;
  top: 50%;
}

@mixin destroy-vertical-center {
  @include transform(none);
  position: static;
  top: auto;
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

///
/// Vertical & Horizontal centerer
///
@mixin vertical-horizontal-center {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin wrapper {
  max-width: $max-width-large;
  padding: 0 20px;
  margin: auto;
}

///
/// These are the padding-top values (in ems) each font needs to offset the
/// descender height for vertically-centering text.
///
$text-offsets: (
  text: 0.26667,
  heading: 0.3,
  subheading: 0.1,
  cta: 0.4,
  cta--lowercase: 0.18333
);

///
/// Use this to vertically center text by adding padding-top to the element
/// so that the amount of space between the cap line and top bearing line is
/// equal to the amount of space between the base line and the bottom bearing
/// line:
///
/// @include font-padding(text);
///
/// Use this if you want the element to be a certain height (in pixels),
/// adjusted for that top padding (assuming the font is displayed at 14px and
/// you want height of the element to be 30px):
///
/// @include font-padding(subheading, 14px, 30px)
///
@mixin font-padding($font, $font-size-px: false, $height-px: false) {
  @if $font-size-px {
    // If $font-size-px is convert and round the text-offset to pixels:
    padding-top: round(map-get($text-offsets, $font) * $font-size-px);
    // If $height-px was also passed, set the line-height to that with the
    // padding-top we just added subtracted.
    @if $height-px {
      $offset-px: map-get($text-offsets, $font) * $font-size-px;
      line-height: round($height-px - $offset-px);
    }
  } @else {
    padding-top: #{map-get($text-offsets, $font)}em;
  }
}

@mixin link-hover($color: $color-link-hover, $text-decoration: underline) {
  @include transition(color, 200ms);
  &:hover {
    color: $color;
    text-decoration: $text-decoration;
  }
}

// 2018 Creative Refresh - mixins for new designs
// new shade picker elements
@mixin cr18-shade-swatch {
  background-clip: padding-box;
  border: 1px solid $color-white;
  border-radius: 50%;
  display: block;
  &.shade-active {
    border: 1px solid $color-black;
  }
}

@mixin cr18-shade--disabled {
  background: linear-gradient(
    to top left,
    transparent calc(50% - 1px),
    $color-cr18-black,
    transparent calc(50% + 1px)
  );
  background: -ms-linear-gradient(top left, transparent 48%, $color-cr18-black, transparent 52%);
}

@mixin cr18-cta {
  background-color: $color-black;
  color: $color-white;
  display: block;
  &:visited {
    color: $color-white;
  }
}

@mixin link($color: $color-link, $hover-color: $color-link-hover) {
  @include a1;
  @include transition(color, 200ms);
  color: $color;
  cursor: pointer;
  // Don't do this.
  // text-decoration: underline;
  &:hover {
    color: $hover-color;
    text-decoration: none;
  }
}

@mixin button-basic() {
  @include font--subheading();
  @include font-padding(subheading, 24px, 44px);
  @include font-smoothing(antialiased);
  @include appearance(none);
  @include box-shadow(0 0 0 1px $color-black);
  background-color: $color-dark-gray;
  color: $color-white;
  text-align: center;
  text-decoration: none;
  border: solid 1px $color-white;
  // outline: solid 1px $color-black; Outline was being buggy, uncomment and remove box-shadow to undo
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 1px;
  font-size: 24px;
  &:hover {
    // outline: solid 1px $color-black;
    text-decoration: none;
  }
}

// constrain content area to 1280
@mixin content-contain {
  max-width: 1280px;
  margin: 0 auto;
}

@mixin selectBox {
  @include font--subheading;
  @include appearance(none);
  @include font-padding(subheading, 18px, 46px);
  // IE sucks and will not account for line-height in inputs:
  height: 48px;
  font-size: 18px;
  padding-left: 15px;
  // Extra 20px on the right to accont for arrow
  padding-right: 40px;
  border: 1px solid $color-gray;
  border-radius: 0;
  display: block;
  background-color: $color-white;
  border: 1px solid $color-gray;
  min-width: 100%; // Otherwise breaks on resize
  max-width: 100%;
  position: relative;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &.js-tabbed-menu {
    padding-right: 0;
    padding-left: 0;
    border: none;
    margin: 0;
    height: 49px;
  }
}

// Creative refresh 2018
// @todo deprecate as new styleguide rolls out
// includes temporary base style changes
@mixin base--cr18 {
  body {
    letter-spacing: 0;
  }
}

// Creative refresh 2018
// @todo deprecate as new styleguide rolls out
// includes temporary gnav style changes
@mixin gnav--cr18 {
  .page-header__nav__main .menu__link--lvl-1 {
    font-size: 15px;
  }
}

// Creative refresh 2018
// @todo deprecate as new styleguide rolls out
// includes temporary selectbox style changes
@mixin forms--cr18 {
  // selectbox--new
  select.selectbox--new,
  .selectbox--new .selectBox,
  .selectbox--new.selectBox {
    @include bordered-triangle(12px, $color-white, down, $color-cr18-black);
    min-width: 80px;
    background-color: $color-white;
    border: 1px solid $color-cr18-black;
    font-size: 15px;
    font-weight: normal;
    height: 30px;
    letter-spacing: 0;
    line-height: 30px;
    padding: 0 40px 0 15px;
    //@todo replace with SVG when new icons are in place
    background-image: url('/media/images/global/arrow_down_black.png');
    background-position: calc(100% - 7px) 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    @include breakpoint($large-up) {
      background-image: none;
    }
    .selectBox-arrow {
      display: none;
    }
    &:before,
    &:after {
      top: 50%;
      left: auto;
      margin-top: -3px;
    }
    &:before {
      right: 9px;
    }
    &:after {
      right: 10px;
    }
  }
  .selectbox--new {
    .selectBox-menuShowing,
    &.selectBox-menuShowing {
      @include bordered-triangle(12px, $color-white, up, $color-cr18-black);
      &:before,
      &:after {
        top: 50%;
        left: auto;
      }
      &:before {
        right: 9px;
        margin-top: -10px;
      }
      &:after {
        right: 10px;
        margin-top: -8px;
      }
    }
    option {
      font-size: 15px;
      line-height: 30px;
    }
    .selectBox-label {
      letter-spacing: 0;
    }
    &-selectBox-dropdown-menu {
      font-size: 15px;
      z-index: 100;
      li {
        a:hover {
          background: $color-light-gray;
          text-decoration: none;
        }
        &.sku-list-sizes__button-dropdown {
          a {
            text-transform: capitalize;
          }
        }
      }
      .selectBox-selected {
        background: $color-light-gray;
      }
    }
  }
  // selectbox--plain
  select.selectbox--plain,
  .selectbox--plain .selectBox,
  .selectbox--plain.selectBox {
    @include bordered-triangle(12px, $color-white, down, $color-cr18-black);
    background-color: $color-white;
    background-image: none;
    border: none;
    font-family: $font--futura-book;
    font-size: 15px;
    font-weight: normal;
    height: 15px;
    letter-spacing: 0;
    line-height: 15px;
    padding: 0 40px 0 15px;
    .selectBox-arrow {
      display: none;
    }
    &:before,
    &:after {
      top: 5px;
      right: 9px;
      left: unset;
      margin-top: 0;
    }
    &:after {
      right: 10px;
    }
  }
  .selectbox--plain {
    .selectBox-menuShowing,
    &.selectBox-menuShowing {
      @include bordered-triangle(12px, $color-white, up, $color-cr18-black);
      &:before,
      &:after {
        top: 0;
        right: 9px;
        left: unset;
      }
      &:after {
        right: 10px;
      }
    }
    option {
      font-size: 15px;
      line-height: 30px;
    }
    .selectBox-label {
      letter-spacing: 0;
    }
    &-selectBox-dropdown-menu {
      font-size: 15px;
      text-transform: none;
      li {
        a {
          border: none;
          white-space: nowrap;
          &:hover {
            background: none;
            color: $color-light-gray;
            text-decoration: none;
          }
        }
      }
      .selectBox-selected {
        background: $color-light-gray;
        font-weight: normal;
      }
    }
  }
}

// Common base styles used by SVG icons
@mixin svg-icon {
  fill: $color-black;
  display: block;
  width: 35px;
  height: 35px;
  color: $color-black;
  &:hover {
    fill: $color-mid-gray;
  }
}

//
// Unify parent with nested child selector
// ie - nested selector tag to be unified with parent section
//

@use 'sass:selector';

@mixin unify-parent($child) {
  @at-root #{selector-unify(&, $child)} {
    @content;
  }
}

@mixin toggle-transition(
  $type: all,
  $duration: 0.333s,
  $easing: cubic-bezier(0.65, 0.05, 0.36, 1)
) {
  transition: $type $duration $easing;
}

// default styling for paragraphs/body text bold
@mixin checkout-body-text--bold {
  font-family: $bold-font-family;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

@mixin checkout-body-text--total {
  font-family: $bold-font-family;
  font-size: get-rem(18px);
  letter-spacing: 0;
  line-height: get-line-height(18px, 24px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $main-font;
  font-size: get-rem(24px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(18px, 24px);
}

// sub header/title style - medium version - style
@mixin checkout-sub-heading--medium-style {
  font-family: $main-font;
  font-size: get-rem(24px);
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1.2px;
  line-height: get-line-height(24px, 24px);
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $main-font;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for paragraphs/body text - style
@mixin checkout-body-text--medium-style {
  font-family: $main-font;
  font-size: get-rem(16px);
  text-transform: none;
  font-weight: 400;
  letter-spacing: 0.76px;
  line-height: get-line-height(16px, 16px);
}

// default styling for paragraphs/body text - link
@mixin checkout-body-text--medium-link {
  font-family: $main-font;
  font-size: get-rem(14px);
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.56px;
  line-height: get-line-height(14px, 14px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 14px);
}

// sub header/title style - small version
@mixin checkout-sub-heading--small {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 18px);
}

// sub header/title style - small version - style
@mixin checkout-sub-heading--small-style {
  font-family: $font--futura-demi;
  font-size: get-rem(15px);
  letter-spacing: 0;
  line-height: get-line-height(15px, 20px);
  text-transform: uppercase;
}

// sub header/title style - small version - style 2
@mixin checkout-sub-heading--small-style2 {
  font-family: $main-font;
  font-size: get-rem(15px);
  letter-spacing: 0;
  line-height: get-line-height(15px, 20px);
}

// cart/title style - small version
@mixin checkout-cart-title--small-text {
  font-family: $font--futura-demi;
  font-size: get-rem(10px);
  letter-spacing: 0;
  line-height: get-line-height(10px, 7px);
}

// cart/title style - small version
@mixin checkout-cart-title--small-subtext {
  font-family: $font--futura-demi;
  font-size: get-rem(10px);
  letter-spacing: 0;
  line-height: get-line-height(10px, 7px);
}

//shared checkout v2 style related mixins
@mixin checkout-text--small {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 18px);
}

@mixin checkout-text--medium {
  font-family: $main-font;
  font-size: get-rem(14px);
  line-height: get-line-height(18px, 21px);
  @include breakpoint($medium-up) {
    font-size: get-rem(18px);
  }
}

@mixin checkout-text--medium-bold {
  font-family: $main-font;
  font-size: get-rem(14px);
  font-weight: bold;
  line-height: get-line-height(18px, 21px);
  @include breakpoint($medium-up) {
    font-size: get-rem(18px);
  }
}

@mixin checkout-text--large {
  font-family: $main-font;
  font-size: get-rem(16px);
  line-height: get-line-height(20px, 24px);
  @include breakpoint($medium-up) {
    font-size: get-rem(20px);
  }
}

@mixin checkout-text--large-bold {
  font-family: $main-font;
  font-size: get-rem(16px);
  font-weight: bold;
  line-height: get-line-height(20px, 24px);
  @include breakpoint($medium-up) {
    font-size: get-rem(20px);
  }
}

@mixin checkout-text--heading {
  font-family: $main-font;
  font-size: get-rem(20px);
  line-height: get-line-height(20px, 24px);
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: get-rem(44px);
  }
}

@mixin checkout-button-v2_sc_design {
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  font-size: get-rem(14px);
  font-family: $main-font;
  font-weight: bold;
  height: 40px;
  line-height: get-line-height(14px, 13px);
  padding: 12px 20px;
  text-decoration: none;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: get-rem(18px);
    line-height: get-line-height(18px, 16px);
  }
}

// Creative refresh 2018
@mixin filters--cr18 {
  .mpp-filter {
    float: $ldirection;
    z-index: 20;
    @include breakpoint($large-up) {
      z-index: 10;
    }
    &-interactive {
      @include breakpoint($medium-up) {
        width: calc(100% - 100px);
      }
    }
  }
}

// includes temporary search related changes
@mixin product-brief-search--cr18 {
  .typeahead-wrapper {
    .product-brief {
      @include breakpoint($medium-up) {
        padding: 37px 20px 34px;
      }
    }
    .product-brief__subtitle,
    .product-brief__price {
      font-size: 15px;
      @include breakpoint($medium-up) {
        font-size: 15px;
      }
    }
    .product-brief__img {
      max-width: 150px;
      @include breakpoint($medium-up) {
        max-width: 150px;
      }
    }
    .product-brief__title__link {
      color: $color-cr18-black;
    }
  }
  .search_results__container {
    .product-brief__cta--quickshop {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
}

@mixin color-states {
  &.light {
    color: $color-white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &.dark {
    color: $color-black;
  }
}

// Bold link: Batch 1 styleguide, pg. 14
@mixin link--bold {
  @include link;
  font-weight: bold;
}


@mixin split-width-equal-height {
  .hero__wrapper {
    height: 100%;
    .hero__media-wrapper {
      height: 100%;
      .video-block {
        height: 100%;
        &__media {
          height: 100%;
          &-video {
            height: 100%;
            .video-detail-header {
              height: 100%;
              .video-detail__video-wrapper {
                height: 100%;
                .videojs-video {
                  height: 100%;
                }
              }
            }
          }
        }
      }
      .basic-media {
        height: 100%;
        .mantle-media-asset {
          height: 100%;
          img {
            object-fit: cover;
          }
        }
      }
    }
  }
}