@import '../../../or_base/scss/theme-bootstrap';

$basic-grid-gutter: 12px;
$basic-grid-small-gutter: 10px;

.basic-grid {
  a:hover {
    text-decoration: none;
  }
  @include pie-clearfix;
  &__title,
  &__sub-title {
    margin: 0;
    text-align: center;
    font-weight: inherit;
    letter-spacing: inherit;
    p {
      font-size: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      margin: 0;
    }
  }
  &__title,
  &__sub-title {
    grid-column: 1/3;
  }
  &__item {
    padding: 0 0 $basic-grid-gutter;
    .basic-grid--small-cols-2 &,
    .basic-grid--small-cols-3 &,
    .basic-grid--small-cols-4 & {
      float: left;
      padding: 0 0 $basic-grid-gutter $basic-grid-small-gutter;
    }
    .basic-grid--small-cols-2 & {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 50%;
      }
    }
    .basic-grid--small-cols-3 & {
      width: 33.333%;
    }
    .basic-grid--small-cols-4 & {
      width: 25%;
    }
    @include breakpoint($medium-up) {
      float: left;
      width: 33.333%;
      padding: 0 0 $basic-grid-gutter $basic-grid-gutter;
      .basic-grid--medium-cols-1 & {
        float: none;
        width: auto;
        padding: 0 0 $basic-grid-gutter;
      }
      .basic-grid--medium-cols-2 & {
        width: 50%;
      }
      .basic-grid--medium-cols-3 & {
        width: 33.333%;
      }
      .basic-grid--medium-cols-4 & {
        width: 25%;
      }
      .basic-grid--medium-cols-2 &,
      .basic-grid--medium-cols-3 &,
      .basic-grid--medium-cols-4 & {
        float: left;
        padding: 0 0 $basic-grid-gutter $basic-grid-gutter;
      }
    }
    &.item--no-padding {
      padding: 0;
    }
  }
  &.basic-grid--full-width,
  &.basic-grid--max-width-960,
  &.basic-grid--max-width-1070,
  &.basic-grid--max-width-1280,
  &.basic-grid--max-width-1800 {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  &.basic-grid--full-width {
    width: 100%;
    max-width: 100%;
  }
  &.basic-grid--max-width-960 {
    max-width: 960px;
  }
  &.basic-grid--max-width-1070 {
    max-width: 1070px;
  }
  &.basic-grid--max-width-1280 {
    max-width: 1280px;
  }
  &.basic-grid--max-width-1800 {
    max-width: 1800px;
  }
}

.basic-grid--small-cols-2,
.basic-grid--small-cols-3,
.basic-grid--small-cols-4 {
  padding-right: $basic-grid-small-gutter;
  @include breakpoint($medium-up) {
    padding-right: unset;
  }
}
.basic-grid--small-cols-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  @include breakpoint($medium-up) {
    display: block;
  }
}
